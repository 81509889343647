<template>
  <b-card-actions title="Accounts" action-collapse :collapsed="false" no-body class="m-0">
    <div class="m-2 mb-0">
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            <b-button
              variant="outline-success"
              :to="{ name: 'apps-accounting-receivable-cohort-import', query: { cohort: cohortData.id, mode: 'edit' } }"
              class="mr-1"
            >
              <span class="text-nowrap">Edit Accounts</span>
            </b-button>
            <b-button
              variant="info"
              :to="{ name: 'apps-accounting-receivable-cohort-import', query: { cohort: cohortData.id } }"
              class="mr-1"
            >
              <span class="text-nowrap">Import Accounts</span>
            </b-button>
            <b-button variant="primary" disabled @click="isAddNewAccountActive = true">
              <span class="text-nowrap">Add Account</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refAccountListTable"
      class="position-relative"
      :items.sync="fetchAccounts"
      responsive
      :fields="tableColumns"
      :filter.sync="searchQuery"
      :filterByFormatted="true"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    >
      <!-- Column: Session -->
      <template #cell(studentId)="data">
        {{ data.item.student.studentId }}
      </template>

      <!-- Column: Session -->
      <template #cell(student.fullName)="data">
        <b-link
          :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.student.fullName }}
        </b-link>
        <small class="text-muted">#{{ data.item.student.studentId }}</small>
      </template>

      <!-- Column: Cohort -->
      <template #cell(cohort)="data">
        <div class="text-nowrap" v-if="data.item.cohort">
          <b-link
            :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.cohort.name }}
          </b-link>
          <small class="text-muted">{{ title(data.item.cohort.status) }}</small>
        </div>
        <div class="text-nowrap" v-else>
          <feather-icon
            :id="`account-row-${data.item.id}-set-cohort-icon`"
            icon="PlusCircleIcon"
            class="cursor-pointer"
            size="16"
            @click="startSessionSelect(data.item)"
          />

          <b-tooltip title="Assign Cohort" class="cursor-pointer" :target="`account-row-${data.item.id}-set-cohort-icon`" />

          Unassigned
        </div>
      </template>

      <!-- Column: Attributes - Program -->
      <template #cell(profile.attributes.fundingSource)="data">
        <b-badge
          v-if="data.item.profile && data.item.profile.attributes"
          pill
          :variant="`light-${resolveAccountProgramVariant(data.item.profile.attributes.fundingSource)}`"
          class="text-nowrap text-capitalize"
        >
          {{ title(data.item.profile.attributes.fundingSource) }}
        </b-badge>
        <div class="text-nowrap" v-else>Unassigned</div>
      </template>

      <!-- Column: status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveAccountStatusVariant(data.item.status)}`"
          class="text-nowrap text-capitalize"
        >
          {{ title(data.item.status) }}
        </b-badge>
      </template>

      <!-- Column: total -->
      <template #cell(total)="data">
        <span class="text-nowrap">{{ money(data.item.total) }} ({{ data.item.charges.length }})</span>
      </template>

      <!-- Column: balance -->
      <template #cell(totalPaid)="data">
        <span class="text-nowrap"
          >{{ money(data.item.totalPaid) }} ({{ data.item.payments.filter((a) => a.status === 'paid').length }})</span
        >
      </template>

      <!-- Column: balance -->
      <template #cell(balance)="data">
        <span class="text-nowrap">{{ money(data.item.balance) }}</span>
      </template>

      <!-- Column: Session -->
      <template #cell(createdAt)="data">
        {{ moment(data.item.createdAt).utc().format('L') }}
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveAccountStatusVariant(data.item.status)}`" class="text-capitalize">
          {{ title(data.item.status) }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-link :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
            <feather-icon :id="`account-row-${data.item.id}-edit-icon`" icon="EditIcon" class="cursor-pointer" size="16" />
            <b-tooltip title="Edit Account" class="cursor-pointer" :target="`account-row-${data.item.id}-edit-icon`" />
          </b-link>

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="!data.item.profile" @click="assignRosterProfile(data.item)">
              <feather-icon icon="UserPlusIcon" />
              <span class="align-middle ml-50">Assign Profile</span>
            </b-dropdown-item>
            <b-dropdown-item @click="archiveAccount(data.item.id)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalAccounts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <cohort-add-account
      :cohort-data="cohortData"
      :is-add-new-account-active.sync="isAddNewAccountActive"
    ></cohort-add-account>

    <assign-account-profile
      v-on:refresh="fetchAccounts"
      v-if="assignProfile.accountData.id && assignProfile.isAssignProfileOpen"
      :is-assign-profile-open.sync="assignProfile.isAssignProfileOpen"
      :student-data.sync="assignProfile.studentData"
      :account-data.sync="assignProfile.accountData"
    />
  </b-card-actions>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardBody,
  BCardTitle,
  BCardText,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted, computed } from '@vue/composition-api';
import { title, avatarText } from '@core/utils/filter';
import useCohortAccountsList from './useCohortAccountsList';
import receivableStoreModule from '../receivableStoreModule';
import moment from 'moment';

import CohortAddAccount from './components/CohortAddAccount';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import router from '@/router';
import useAccountsList from '@/views/apps/accounting/receivable/account-list/useAccountsList';
import AssignAccountProfile from '@/views/apps/accounting/receivable/account-view/components/AssignAccountProfile.vue';

export default {
  components: {
    AssignAccountProfile,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    BCardTitle,
    BCardActions,
    BCardText,
    BTooltip,

    vSelect,
    CohortAddAccount,
  },
  props: {
    cohortData: { type: Object, required: true },
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },

    startUpload(account, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        account,
        documentCategory,
        documentType,
      };
    },
    assignRosterProfile(account) {
      this.assignProfile.accountData = account;
      this.assignProfile.studentData = account.student;
      this.assignProfile.isAssignProfileOpen = true;
    },
    archiveAccount(accountId) {
      store
        .dispatch('app-accounting-receivable/updateAccount', {
          id: accountId,
          accountData: {
            status: 'archived',
          },
        })
        .then(() => {
          this.$emit('refresh', true);
          this.refetchData();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  data: () => {
    return {
      isAddNewAccountActive: false,
      editClassData: {},

      assignProfile: {
        isAssignProfileOpen: false,
        accountData: {},
        studentData: {},
      },

      upload: {
        isUploadDocumentOpen: false,

        account: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },
    };
  },
  mounted() {},
  setup(props) {
    const RECEIVABLE_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME))
      store.registerModule(RECEIVABLE_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIVABLE_APP_STORE_MODULE_NAME);
    });

    const cohortBodyId = ref({ id: router.currentRoute.params.id });

    const { resolveAccountProgramVariant, resolveAccountYNVariant, resolveAccountStatusVariant } = useAccountsList();

    const {
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      dataMeta,

      tableColumns,
      fetchAccounts,
      refetchData,
      searchQuery,
      totalAccounts,
    } = useCohortAccountsList(cohortBodyId);

    return {
      // Sidebar

      fetchAccounts,
      refetchData,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      totalAccounts,

      dataMeta,

      moment,

      searchQuery,

      resolveAccountProgramVariant,
      resolveAccountYNVariant,
      resolveAccountStatusVariant,

      // Filter
      avatarText,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
