import { computed, ref, watch } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import store from '@/store';

export default function useCohortsList() {
  // Use toast
  const toast = useToast();

  const refCohortListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, stickyColumn: true, variant: 'primary' },
    { key: 'program.startDate', label: 'Start Date', sortable: true },
    { key: 'program.endDate', label: 'End Date', sortable: true },
    // { key: 'program.name', label: 'Program', sortable: true },
    // { key: 'session.name', label: 'Session', sortable: true },
    { key: 'total', label: 'Debit', sortable: true },
    { key: 'totalPaid', label: 'Credit', sortable: true },
    { key: 'balance', label: 'Balance', sortable: true },
    { key: 'status', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    // { key: 'program.startDate', label: 'P Starts', sortable: true },
    // { key: 'program.endDate', label: 'P Ends', sortable: true },
    { key: 'actions', sortable: false },
  ];

  const {
    search: qSearch,
    status: qStatus,
    funding: qFunding,

    perPage: qPerPage,
    page: qCurrentPage,
    sortBy: qSortBy,
    sortDesc: qSortDesc,
  } = router.currentRoute.query;
  // todo: do those reports bc that'd be pretty cool tbh

  const perPage = ref(qPerPage || 10);
  const totalCohorts = ref(0);
  const currentPage = ref(qCurrentPage || 1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref(qSearch);
  const sortBy = ref(qSortBy || 'createdAt');
  const isSortDirDesc = ref(qSortDesc || true);

  const statusFilter = ref(qStatus);
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);
  const fundingFilter = ref(qFunding);

  const dataMeta = computed(() => {
    const localItemsCount = refCohortListTable.value ? refCohortListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCohorts.value,
    };
  });

  const refetchData = () => {
    refCohortListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      statusFilter,
      createAtStartFilter,
      createAtEndFilter,
      fundingFilter,
      sortBy,
      isSortDirDesc,
    ],
    () => {
      refetchData();

      const query = {
        search: searchQuery.value,

        status: statusFilter.value,

        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      };

      if (!query.search) delete query.search;
      if (!query.status) delete query.status;

      if (query.page === '1' || query.page === 1) delete query.page;
      if (query.perPage === '10' || query.perPage === 10) delete query.perPage;
      if (query.sortBy === 'createdAt') delete query.sortBy;
      if (query.sortDesc === 'true' || query.sortDesc === true) delete query.sortDesc;

      router.replace({
        query,
      });
    }
  );

  const fetchCohorts = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'session',
    };

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    /*
    resumeFilter,
    coeFilter,
    submissionFilter, */

    store
      .dispatch('app-accounting-receivable/fetchCohorts', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalCohorts.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cohorts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCohortYNVariant = (status) => {
    if (status === 'yes') return 'success';
    if (status === 'no') return 'danger';
    return 'warning';
  };

  const resolveCohortStatusVariant = (status) => {
    if (status === 'archived') return 'dark';
    if (status === 'active') return 'success';
    if (status === 'closed') return 'info';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Closed', value: 'closed' },
    { label: 'Archive', value: 'archived' },
  ];

  const yesNoOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  return {
    fetchCohorts,
    tableColumns,
    perPage,
    currentPage,
    totalCohorts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCohortListTable,

    resolveCohortYNVariant,
    resolveCohortStatusVariant,

    refetchData,

    // Extra Filters
    createAtStartFilter,
    createAtEndFilter,

    //

    statusOptions,
    statusFilter,
    yesNoOptions,
  };
}
