<template>
  <b-card class="card-account">
    <h3 class="text-center">{{ cohortSummary.cohort.name }}</h3>
    <hr class="mb-1" />

    <div class="price-details">
      <ul class="list-unstyled">
        <li class="price-detail">
          <div class="detail-title">Program Dates</div>
          <div class="detail-amt discount-amt">
            {{ moment.utc(cohortSummary.cohort.program.startDate).format('L') }}
            {{ ` - ` }}
            {{ moment.utc(cohortSummary.cohort.program.endDate).format('L') }}
          </div>
        </li>
        <li class="price-detail">
          <div class="detail-title">Status</div>
          <div class="detail-amt">
            {{ title(cohortSummary.cohort.status) }}
          </div>
        </li>
        <hr v-if="cohortSummary.session">
        <li v-if="cohortSummary.session" class="price-detail">
          <div class="detail-title">Roster Session</div>
          <div class="detail-amt">
            <router-link :to="{ name: 'apps-roster-session-view', params: { id: cohortSummary.session.id } }">
              {{ cohortSummary.session.name }}
            </router-link>
          </div>
        </li>
        <li v-if="cohortSummary.session" class="price-detail">
          <div class="detail-title">Session Dates</div>
          <div class="detail-amt discount-amt">
            {{ moment(cohortSummary.session.startDate).format('L') }}
            {{ ` - ` }}
            {{ moment(cohortSummary.session.endDate).format('L') }}
          </div>
        </li>
      </ul>

      <hr />

      <b-button
        :to="{ name: 'apps-accounting-receivable-cohort-edit', params: { id: cohortSummary.cohort.id } }"
        variant="primary"
        size="sm"
        block
      >
        Edit Cohort
      </b-button>

      <b-button
        @click='initiateDownload'
        variant="secondary"
        size="sm"
        block
      >
        Export
      </b-button>

      <b-button
        @click='initiateReportsQueue'
        variant="outline-primary"
        size="sm"
        block
      >
        Queue Reports
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCohortsList from '../cohort-list/useCohortsList';
import { title } from '@core/utils/filter';
import usAppConfig from '@core/app-config/useAppConfig';
import moment from 'moment';
import fileDownload from 'js-file-download';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

const { skin } = usAppConfig();

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BLink,
  },
  props: {
    cohortSummary: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyCohortPassword() {
      console.log(`copying cohort password`);
      this.$copyText(
        `https://recruiters.intellectualpoint.com/cohorts/${this.cohortSummary.id}?code=${btoa(this.cohortSummary.password)}`
      )
        .then((a) => {
          console.log(`copied`, a);
        })
        .catch((e) => console.error(e));
    },
    initiateDownload() {
      const params = { cohort: this.cohortSummary.id, ssn: false };

      this.$store
        .dispatch('app-accounting-receivable/exportCohortXlsx', {
          id: this.cohortSummary.cohort.id,
          queryParams: params,
        })
        .then((response) => {
          const { data } = response;
          fileDownload(data, `${this.cohortSummary.cohort.name} Report.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        })
        .catch((error) => {
          console.error(`failed to download ar cohort export`, error);
        });
    },
    initiateReportsQueue() {
      const params = { cohort: this.cohortSummary.id };

      this.$store
        .dispatch('app-accounting-receivable/exportCohortFinancialReports', { id: this.cohortSummary.cohort.id })
        .then((response) => {
          const { data } = response;
          const { job } = data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Job queued successfully',
              variant: 'success',
              text: 'Successfully created a job to create the financial reports.',
            },
          });
          
          this.$router.push({name: 'apps-job-list', query: {jobId: job.id, refresh: '10s'}})
        })
        .catch((error) => {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to queue financial reports.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });

          console.error(`failed to download ar cohort export`, error);
        });

    }
  },
  setup() {
    const { resolveCohortStatusVariant } = useCohortsList();
    return {
      avatarText,
      resolveCohortStatusVariant,
      title,
      skin,
      moment,
    };
  },
};
</script>

<style scoped lang="scss">
$card-spacer-y: 0.75rem !default;

.card-account {
  .card-body {
    position: relative;
    padding: 1.5rem 1rem $card-spacer-y 1rem !important;
  }
}

.price-details {
  .price-title {
    font-weight: 600 !important;
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
  }

  .price-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .detail-title {
      &.detail-total {
        font-weight: bolder !important;
      }
    }
  }
}
</style>
